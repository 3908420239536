<template>
  <div class="relative h-full lg:mt-0 -mt-4 bg-white rounded-t-xl flex flex-wrap">

    <client-card-large v-if="selectedClient" :customer="selectedClient" />

    <transition name="slide">
      <div v-if="createCustomer"
        class="w-full h-screen bg-white bg-opacity-0 absolute top-0 flex justify-end top-0 left-0 z-50">
        <create-customer v-if="createCustomer" :customer="customer" />
      </div>
    </transition>

    <nav-menu />
    <div class="w-full bg-gray-900">
      <div class="container mx-auto h-40 items-center flex">

        <div class="flex-shrink text-left">
          <h1 class="ml-4 text-white header-heavy text-3xl tracking-tight">
            Client Book
          </h1>
        </div>

        <div class="flex-grow flex flex-wrap ml-auto">
          <div class="flex-shrink ml-auto mr-6">
            <input v-if="currentUser.role == 'admin'" type="text" v-model="search" placeholder="Start typing..."
              class="w-96 rounded text-left px-4 text-base h-12 bg-gray-800 outline-none px-2 text-gray-300" />
          </div>
          <div class="flex-shrink ml-4">
            <div @click="exportToCSV()"
              class="rounded bg-secondary cursor-pointer text-white h-12 px-4 items-center justify-center flex">
              <i class="fa-solid fa-file-csv mr-2"></i> Export Customers
            </div>
          </div>
          <div class="flex-shrink ml-4">
            <div @click="createCustomer = true"
              class="rounded bg-primary cursor-pointer text-white h-12 px-4 items-center justify-center flex">
              <svg class="w-6 h-6 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6">
                </path>
              </svg>
              Add Customer
            </div>
          </div>
        </div>
      </div>
    </div>

    <table v-if="rowData.length > 0" class="table-auto overflow-x-scroll bg-gray-100 w-full">
      <thead class="h-10 border-r border-gray-300 bg-secondary font-normal text-xs text-gray-300">
        <th>
          Company
        </th>
        <th>
          Projects
        </th>

        <th>
          
        </th>
        <th>
          Delete
        </th>
      </thead>
      <tbody>

        <tr v-for="(i, index) in rowData" :key="index" class="cursor-pointer">
          <td @click="selectedClient = i" class="w-96 px-10 text-left font-bold text-sm border border-gray-200">
            {{ i.company }}
          </td>
          <td @click="selectedClient = i" class="border border-gray-200">
            <div class="w-full flex flex-wrap">
              <div class="w-full" v-for="(p, index) in i.project">
              <div v-if="p.active != false" class="w-full text-xs text-left flex items-center" >
                #{{p.projectId }} - {{p.projectName }} <div v-if="p.estimatedEndDate" :class="{'bg-red-600 text-white': isOverdue(p.estimatedEndDate), 'bg-gray-300 text-grey-800': !isOverdue(p.estimatedEndDate)}" class="flex-shrink rounded px-4 py-1 ml-2 ">End Date <span v-if="p.estimatedEndDate">{{ $moment(p.estimatedEndDate.seconds * 1000).format("DD/MM/YYYY") }} </span><span v-else>Undefined</span></div>
              </div>
            </div>
            </div>
          </td>
          <td @click="selectedClient = i" class="border border-gray-200  px-2 w-56">
            <div
              class="w-full hover:bg-secondary h-8 flex items-center justify-center bg-gray-900 text-white text-xs rounded cursor-pointer">
              View Projects</div>
          </td>
          <td class="w-10 border border-gray-200">
            <i @click="deleteProject(i)" class="fa-solid fa-xmark text-red-600 cursor-pointer hover:text-red-800"></i>
          </td>
        </tr>

      </tbody>
    </table>
  </div>
</template>

<script>
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { postFire, setFire, deleteFire, getJobsByCustomerId, getProjectByCustomerId } from "../firestore/utils";
import { getTimeStamp, db } from "../firebase";
import { bus } from "../main";

var sortJsonArray = require("sort-json-array");
import { Parser } from "json2csv";
import CreateCustomer from "@/components/cards/createCustomer.vue";
import ClientCardLarge from "@/components/cards/clientCardLarge.vue";
import NavMenu from "@/components/headers/navMenu"
export default {
  props: ["date", "prevDate", "search", "triggerExport"],
  data() {
    return {
      selectedClient: null,
      createCustomer: false,
      // search: "",
      currentlySortedBy: null,
      columnHover: -1,
      columnDefs: [
        {
          field: "company",
          width: "w-10 text-left",
          filterField: "",
          openFilter: false,
          filterValue: "",
          filterValueArray: [],
          sortDirection: "asc",
        }
      ],
      rowData: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  watch: {
    columnDefs: {
      handler() {
        // this.filterResults();
      },
      deep: true,
    },
    search() {
      this.filterResults();
    },
    date() {
      this.getItems();
    },
    prevDate() {
      this.getItems();
    },
    rowData: {
      handler() {
        // bus.$emit("jobs", this.rowData);
      },
      deep: true,
    },
  },
  methods: {
    isOverdue(estimatedEndDate) {
      // Assuming estimatedEndDate is an object with a seconds property
      const endDate = this.$moment(estimatedEndDate.seconds * 1000);
      return endDate.isBefore(this.$moment(), 'day'); // 'day' is used to compare dates without considering the time part
    },
    async deleteProject(i) {
      if (window.confirm('Are you sure you want to delete?')) {
        await deleteFire({ collection: "customers", doc: i.id });
        const _jobs = await getJobsByCustomerId(i.id);
        // Loop through each client and delete all
        for (let i = 0; i < _jobs.length; i++) {
          await deleteFire({ collection: "jobs", doc: _jobs[i].id });
        }

        this.getItems()
      } else {

      }
    },
    exportToCSV() {
  // Initialize an array to hold the transformed data
  let transformedData = [];

  // Iterate over each item in rowData
  this.rowData.forEach(item => {
    // Check if the item has projects
    if (item.project && item.project.length) {
      // Iterate over each project in the item
      item.project.forEach(project => {
        // Create a new object for each project, including both item and project details
        let projectData = {
          // Spread the item data you want to include
          companyId: item.id,
          company: item.company,
          client: item.client,
          // Add project-specific details
          projectId: project.projectId,
          projectName: project.projectName,
          contact: project.contact,
          phone: project.phone,
          email: project.email,
          address: project.formattedAddress,
          
          estimatedStartDate: project.estimatedStartDate ? this.$moment(project.estimatedStartDate.seconds * 1000).format("DD/MM/YYYY") : "Undefined",
          estimatedEndDate: project.estimatedEndDate ? this.$moment(project.estimatedEndDate.seconds * 1000).format("DD/MM/YYYY") : "Undefined",
          // Add any other project details you need
          poNumber: project.poNumber,
          region: project.region,
          active: project.active,
        };

        // Push the project data to the transformedData array
        transformedData.push(projectData);
      });
    } else {
      // If there are no projects, just include the item data
      let noProjectData = {
        companyId: item.id,
        company: item.company,
        // Handle the case with no projects by setting default or empty values
        projectId: "N/A",
        projectName: "No projects",
        estimatedEndDate: "N/A",
        // Add any other details as needed
      };

      transformedData.push(noProjectData);
    }
  });

  // Proceed with CSV conversion using transformedData
  const json2csvParser = new Parser();
  const csvData = json2csvParser.parse(transformedData);

  // Continue with your existing logic to download the CSV file
  const blob = new Blob([csvData], { type: "text/csv" });
  const anchor = document.createElement("a");
  anchor.href = window.URL.createObjectURL(blob);
  anchor.download = "projects_data.csv";
  anchor.click();
  window.URL.revokeObjectURL(anchor.href);
  anchor.remove();
},

    // exportToCSV() {
    //   // Convert object array to CSV format
    //   let obj = this.rowData;
    //   // delete this.rowData.jobs;

    //   const json2csvParser = new Parser();
    //   const csvData = json2csvParser.parse(obj);

    //   // Create a Blob object with CSV data
    //   const blob = new Blob([csvData], { type: "text/csv" });

    //   // Create a temporary anchor element
    //   const anchor = document.createElement("a");
    //   anchor.href = window.URL.createObjectURL(blob);
    //   anchor.download = "data.csv";

    //   // Programmatically click the anchor element to trigger the download
    //   anchor.click();

    //   // Clean up the temporary anchor element
    //   window.URL.revokeObjectURL(anchor.href);
    //   anchor.remove();
    //   bus.$emit("triggerExport", false);
    // },
    getVisible(items) {
      console.log("items", items);
      const a = items.filter((i) => i.visible);
      console.log("a", a);
      return a;
    },
    filterResults() {
      const url =
        "https://us-central1-ktc-app-33f33.cloudfunctions.net/widgets/search";
      const d = this.$moment(this.prevDate).startOf("day").format("x");
      const ld = this.$moment(this.date).endOf("day").format("x");
      let queryStr = `date:${d} TO ${ld}`;
      this.columnDefs.forEach((c) => {
        this.getVisible(c.filterValueArray).forEach((f, index) => {
          let andOr = "";
          if (queryStr.split("").length > 0) andOr = " AND";
          if (index > 0) andOr = " OR";

          const str = `${andOr} ${c.filterField}:'${f.value}'`;

          if (f.visible) queryStr = queryStr.concat(str);
        });
      });

      this.axios
        .get(url, {
          params: {
            indexName: "customers",
            filter: null,
            search: this.search,
          },
        })
        .then((response) => {
          console.log("response.data", response.data)
          this.rowData = response.data
        })
        .catch((e) => {
          console.log("Error search endpoint", e);
        });
    },
    async getItems() {

      this.rowData = [];
        try{
            const q = query(collection(db, "customers"), orderBy("company"));
            const querySnapshot = await getDocs(q);
            for (const doc of querySnapshot.docs) { 
              const project = await getProjectByCustomerId({customerId: doc.id}); 
              this.rowData.push({...doc.data(), id: doc.id, project: project});
            }
       
        } catch(e){
          console.log("Something went wrong getting customers", e)
        }
 
       
    },
    //CAREFULL THIS CREATES PROJECTS createProjects() {
    //   console.log("saving projects", this.rowData.length)
    //   this.rowData.forEach(async (i, index) => {
    //       console.log("i", i)
    //       const _jobs = await getJobsByCustomerId(i.id);
    //       _jobs.forEach(j => {
    //         const bodyPayload = {
    //             projectId: j.projectId || "",
    //             address: j.address || "",
    //             callFrequency: j.callFrequency || "",
    //             contact: j.contact || "",
    //             phone: j.phone || "",
    //             email: j.email || "",
    //             client: j.client || "",
    //             customerId: j.customerId || "",
    //             company: j.company || "",
    //             estimatedStartDate: j.estimatedStartDate || "",
    //             estimatedEndDate: j.estimatedEndDate || "",
    //             projectType: j.projectType || "",
    //             region: j.region || "",
    //             poNumber: j.poNumber || "",
    //             projectName: j.projectName || "",
    //           }
    //         const payload = {
    //           collection: "projects", doc: JSON.stringify(j.projectId), body: bodyPayload
    //         }
    //         const s = setFire(payload)

    //         console.log("saved", s)
           
    //       })
    //     })

    // }
  },
  created() {
    bus.$on("openClientCardLarge", (data) => {
      // this.getItems();
      this.selectedClient = data;
    });
    bus.$on("closeCreateCustomer", (data) => {
      // this.getItems();
      this.createCustomer = data.status;
    });
    bus.$on("closeClientCard", (data) => {
      // this.getItems();
      this.selectedClient = null;
    });

    this.getItems();
  },
  components: {
    ClientCardLarge,
    CreateCustomer,
    NavMenu
  },
};
</script>
