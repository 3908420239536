<template>
    <div class="p-4">
      <h2 class="text-2xl font-bold mb-4">
        {{ acceptedCount }}/{{ jobIds.length }} jobs have been marked accepted.
      </h2>
      <div>
        <h3 class="text-lg font-semibold mb-2">Job IDs from Query String:</h3>
        <div v-if="loading" class="flex justify-center items-center my-4">
          <div class="loader border-t-4 border-b-4 border-blue-500 rounded-full w-8 h-8 animate-spin"></div>
        </div>
        <ul v-else>
          <li v-for="(job, index) in jobs" :key="index" class="p-4 border rounded shadow mb-2 flex justify-between items-center">
            <span>{{ job.formattedAddress }}</span>
            <span
              class="px-2 py-1 rounded text-white"
              :class="job.accepted ? 'bg-green-500' : 'bg-red-500'"
            >
              {{ job.accepted ? 'Accepted' : 'Not Accepted' }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import { setFire, getFire } from "../firestore/utils";
  
  export default {
    data() {
      return {
        jobIds: [], // Array to hold job IDs from the query string
        jobs: [], // Array to hold job data
        acceptedCount: 0, // Count of accepted jobs
        loading: true // State to track loading
      };
    },
    created() {
      this.extractJobIdsFromQuery();
    },
    methods: {
      async extractJobIdsFromQuery() {
        // Ensure jobIds is an array
        if (Array.isArray(this.$route.query.id)) {
          this.jobIds = this.$route.query.id;
        } else if (this.$route.query.id) {
          this.jobIds = [this.$route.query.id];
        }
  
        // Iterate over jobIds, setFire, and getFire for each
        for (const id of this.jobIds) {
          try {
            await setFire({
              collection: 'jobs',
              doc: id,
              body: {
                accepted: true
              }
            });
  
            // Retrieve job data
            const jobData = await getFire({
              collection: 'jobs',
              doc: id
            });
  
            // Add job data to jobs array
            if (jobData) {
              this.jobs.push(jobData);
              if (jobData.accepted) {
                this.acceptedCount++;
              }
            }
          } catch (error) {
            console.error(`Error processing job ID ${id}:`, error);
          }
        }
        this.loading = false; // Set loading to false once data is fetched
      }
    }
  };
  </script>
  
  <style scoped>
  .loader {
    border-width: 4px;
  }
  </style>
  