<template>
    <div class="container mx-auto p-4">
      <button @click="showModal = true" class="bg-blue-500 text-white py-2 px-4 rounded">Add New Lead</button>
      <div v-if="showModal" style="z-index: 9999999" class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
        <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="showModal = false" class="bg-red-500 text-white py-2 px-4 rounded">Close</button>
          </div>
          <div class="px-4 py-5 sm:p-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">Add New Lead</h3>
            <form @submit.prevent="addLead">
              <div class="grid grid-cols-4 gap-4">
                <div v-for="(input, index) in formInputs" :key="index">
                  <label :for="input.id">{{ input.label }}</label>
                  <input v-if="input.type !== 'textarea' && input.type !== 'select'" :type="input.type" v-model="newLead[input.model]" :id="input.id" class="w-full" :required="input.required" />
                  <textarea v-else-if="input.type === 'textarea'" v-model="newLead[input.model]" :id="input.id" class="w-full" :required="input.required"></textarea>
                  <select v-else-if="input.type === 'select'" v-model="newLead[input.model]" :id="input.id" class="w-full" :required="input.required">
                    <option v-for="option in input.options" :key="option" :value="option">{{ option }}</option>
                  </select>
                </div>
              </div>
              <button type="submit" class="mt-4 bg-blue-500 text-white py-2 px-4 rounded">Add New Lead</button>
            </form>
          </div>
        </div>
      </div>
      <hot-table v-if="this.hotSettings.data.length > 0" :settings="hotSettings" class="mt-4 w-full"></hot-table>
    </div>
  </template>
  
  <script>
  import { HotTable } from '@handsontable/vue';
  import Handsontable from 'handsontable';
  import 'handsontable/dist/handsontable.full.css';
  import { getDocs, collection, query, orderBy, addDoc } from 'firebase/firestore';
  import { storage, db, auth } from "../firebase";
  export default {
    components: {
      HotTable
    },
    data() {
      return {
        showModal: false,
        hotSettings: {
  data: [],
  colHeaders: [
    'Lead Name', 'Client Name', 'Customer Name', 'Project Name', 'Address',
    'Region', 'Meters', 'Contact Name', 'Date', 'Email', 'Estimated Start Date',
    'Estimated End Date', 'Notes', 'Phone', 'Status', 'Missed Reason'
  ],
  columns: [
    { data: 0 },
    { data: 1, type: 'dropdown', source: () => this.clients.map(client => client.name) },
    { data: 2, type: 'dropdown', source: () => this.customers.map(customer => customer.company) },
    { data: 3 },
    { data: 4 },
    { data: 5 },
    { data: 6 },
    { data: 7 },
    { data: 8, type: 'date', dateFormat: 'YYYY-MM-DD' },
    { data: 9 },
    { data: 10, type: 'date', dateFormat: 'YYYY-MM-DD' },
    { data: 11 },
    { data: 12 },
    { data: 13 },
    { data: 14, type: 'dropdown', source: ['call later', 'waiting on customer', 'sold', 'missed'] },
    { data: 15 }
  ],
  rowHeaders: true,
  stretchH: 'all'
},
        leads: [],
        clients: [],
        customers: [],
        newLead: {
          leadName: '', clientName: '', customerName: '', projectName: '', address: '',
          region: '', meters: '', contactName: '', date: '', email: '',
          estimatedStartDate: '', estimatedEndDate: '', notes: '', phone: '',
          status: '', missedReason: ''
        },
        formInputs: [
          { id: 'leadName', label: 'Lead Name', model: 'leadName', type: 'text', required: true },
          { id: 'clientName', label: 'Client Name', model: 'clientName', type: 'select', required: true, options: [] },
          { id: 'customerName', label: 'Customer Name', model: 'customerName', type: 'select', required: true, options: [] },
          { id: 'projectName', label: 'Project Name', model: 'projectName', type: 'text', required: true },
          { id: 'address', label: 'Address', model: 'address', type: 'text', required: true },
          { id: 'region', label: 'Region', model: 'region', type: 'text', required: true },
          { id: 'meters', label: 'Meters', model: 'meters', type: 'text', required: true },
          { id: 'contactName', label: 'Contact Name', model: 'contactName', type: 'text', required: true },
          { id: 'date', label: 'Date', model: 'date', type: 'date', required: true },
          { id: 'email', label: 'Email', model: 'email', type: 'email', required: true },
          { id: 'estimatedStartDate', label: 'Estimated Start Date', model: 'estimatedStartDate', type: 'date', required: true },
          { id: 'estimatedEndDate', label: 'Estimated End Date', model: 'estimatedEndDate', type: 'date', required: true },
          { id: 'notes', label: 'Notes', model: 'notes', type: 'textarea', required: true },
          { id: 'phone', label: 'Phone', model: 'phone', type: 'tel', required: true },
          { id: 'status', label: 'Status', model: 'status', type: 'select', required: true, options: ['call later', 'waiting on customer', 'sold', 'missed'] },
          { id: 'missedReason', label: 'Missed Reason', model: 'missedReason', type: 'text', required: true }
        ]
      };
    },
    created() {
      this.fetchLeads();
      this.fetchClients();
      this.fetchCustomers();
    },
    methods: {
        async fetchLeads() {
  const snapshot = await getDocs(query(collection(db, 'leads'), orderBy('leadName')));
  this.leads = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  const formattedData = this.formatDataForHandsontable(this.leads);
  this.hotSettings.data = formattedData;
},

    formatDataForHandsontable(leads) {
  const data = leads.map(lead => [
    lead.leadName,
    lead.clientName,
    lead.customerName,
    lead.projectName,
    lead.address,
    lead.region,
    lead.meters,
    lead.contactName,
    lead.date,
    lead.email,
    lead.estimatedStartDate,
    lead.estimatedEndDate,
    lead.notes,
    lead.phone,
    lead.status,
    lead.missedReason
  ]);
  return data;
},

      async fetchClients() {
        const snapshot = await getDocs(query(collection(db, 'clients'), orderBy('name')));
        this.clients = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        this.formInputs.find(input => input.id === 'clientName').options = this.clients.map(client => client.name);
      },
      async fetchCustomers() {
        const snapshot = await getDocs(query(collection(db, 'customers'), orderBy('company')));
        this.customers = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        this.formInputs.find(input => input.id === 'customerName').options = this.customers.map(customer => customer.company);
      },
      async addLead() {
        try {
          await addDoc(collection(db, 'leads'), this.newLead);
          this.fetchLeads(); // Refresh the leads list
          this.resetNewLead(); // Reset the form
          this.showModal = false; // Close the modal
        } catch (error) {
          console.error('Error adding document: ', error);
        }
      },
      resetNewLead() {
        this.newLead = {
          leadName: '', clientName: '', customerName: '', projectName: '', address: '',
          region: '', meters: '', contactName: '', date: '', email: '',
          estimatedStartDate: '', estimatedEndDate: '', notes: '', phone: '',
          status: '', missedReason: ''
        };
      }
    }
  };
  </script>
  
  <style scoped>
  /* Add your styles here */
  </style>
  